import { saveAs } from 'file-saver'

/**
 * 下载文件
 * @param {String} path - 下载地址/下载请求地址。
 * @param {String} name - 下载文件的名字/重命名
 */
export const downloadFile = (path, name) => {
  const xhr = new XMLHttpRequest()
  xhr.open('get', path)
  xhr.responseType = 'blob'
  xhr.send()
  // eslint-disable-next-line func-names
  xhr.onload = function() {
    if (this.status === 200 || this.status === 304) {
      const url = URL.createObjectURL(this.response)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    }
  }
}

export const saveFile = (path, name) => {
  saveAs(path, name)
}
